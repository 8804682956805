import React from 'react';
import { Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

const Table = ({ tableData }) => {
    const { column_headers, row_headers, data } = tableData || { column_headers: [], row_headers: [], data: [] };

    // You can define maxWidth for each column, for example:
    const columnMaxWidths = ['150px', '200px', '100px']; // Adjust these values based on your content and number of columns

    const theme = useTheme();
    const borderStyle = `1px solid ${theme.palette.primary.main}`;

    const typographyStyle = {
        fontSize: '14px',
        fontFamily: 'Noto Sans',
        color: theme.palette.primary.main
    };

    return (
        <TableContainer component={Paper}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        {column_headers ? (
                            <>
                                <TableCell style={{ border: borderStyle, padding: 0, }}></TableCell> {/* Empty top-left cell */}
                                {column_headers.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        style={{
                                            border: borderStyle, padding: 0,
                                            maxWidth: columnMaxWidths[index] || '100px', // Use specific or default width
                                        }}
                                    >
                                        <Typography variant='h6' style={typographyStyle}>
                                            {header}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </>
                        ) : (
                            <>
                                <TableCell style={{ border: borderStyle, padding: '2px', paddingLeft: '6px' }}>
                                    <Typography variant='h6' style={typographyStyle}>

                                        Data
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ border: borderStyle, padding: 0 }}></TableCell>
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {row_headers.map((header, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ border: borderStyle, padding: '2px', paddingLeft: '6px' }}>
                                <Typography variant='h6' style={typographyStyle}>
                                    {header}
                                </Typography>
                            </TableCell>
                            {data[index] && data[index].map((item, idx) => (
                                <TableCell
                                    key={idx}
                                    style={{
                                        border: borderStyle,
                                        padding: '2px',
                                        paddingLeft: '4px',
                                        maxWidth: columnMaxWidths[idx % columnMaxWidths.length] || '100px',
                                    }}
                                >
                                    <Typography variant='h6' style={typographyStyle}>

                                        {item}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
}

export default Table;
