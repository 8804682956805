import React, { useState } from 'react';
import { Grid, Typography, Button, Paper, Divider, Box, TextField } from '@mui/material';
import Table from '../QuestionAsset/Table';
import { useTheme } from '@mui/material';

const NumericQuestion = (props) => {
    const theme = useTheme();
    const { handleSubmitAnswerButtonClick, payload } = props;
    const questionContent = payload != null ? payload.content : undefined;
    const questionText = questionContent != undefined ? questionContent.question_text : null;
    const tables = questionContent != undefined ? questionContent.table_data?.tables : null;
    const [answer, setAnswer] = useState('');
    const [error, setError] = useState('');


    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
        setError('');
    };

    const handleSubmit = () => {
        if (answer.trim() === '') {
            setError('Please enter an answer.');
        } else if (!/^-?\d*\.?\d+$/.test(answer)) {
            setError('Please enter a valid numerical answer.');
        } else {
            handleSubmitAnswerButtonClick(answer);
            setAnswer('');
            setError('');
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'stretch', paddingTop: '0px', justifyContent: 'center' }}>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '20%' }}>
                        <Typography
                            variant="h5"
                            sx={{ textAlign: 'left', fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '24px', ml: '20px', mt: '-60px' }}
                        >
                            Question
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{ textAlign: 'left', fontFamily: 'Noto Sans', fontWeight: 'normal', fontSize: '16px', marginTop: '10px', ml: '20px' }}
                        >
                            {questionText}
                        </Typography>
                    </Box>

                    <Divider orientation="vertical" flexItem sx={{
                        height: 'auto',
                        alignSelf: 'stretch',
                        backgroundColor: theme.palette.divider,
                        margin: '0 10px',
                    }} />

                    {/* Table Section */}
                    <Box sx={{ flex: 1, mx: 2, overflowY: 'auto', maxWidth: '60%' }}>
                        <Typography
                            variant="h5"
                            sx={{ textAlign: 'left', fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '24px', mb: 2 }}
                        >
                            Table Data
                        </Typography>
                        <Grid container spacing={2}>
                            {tables && tables.map((tableData, index) => (
                                <Grid item xs={12} key={index}>
                                    <Table tableData={tableData} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Divider orientation="vertical" flexItem sx={{
                        height: 'auto',
                        alignSelf: 'stretch',
                        backgroundColor: theme.palette.divider,
                        margin: '0 16px',
                    }} />

                    {/* Answer Section */}
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', verticalAlign: 'center', maxWidth: '20%' }}>
                        <Typography
                            variant="h5"
                            sx={{ textAlign: 'left', fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '24px', mb: '5px' }}
                        >
                            Your Answer
                        </Typography>
                        <Paper elevation={0} sx={{ marginRight: '15px' }}>
                            <TextField
                                value={answer}
                                onChange={handleAnswerChange}
                                placeholder="Type your answer here..."
                                rows={1}
                                variant="outlined"
                                fullWidth
                            />
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '60px',
                                bottom: theme.spacing(4),
                            }}>
                                {error && (
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                        Please enter numerical answer
                                    </Typography>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    sx={{ width: '130px', fontFamily: '"Noto Sans"', fontSize: '16px' }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default NumericQuestion;
