import React, { useRef, useEffect, useState } from 'react';
import { Grid, Button, Typography, Box, Divider, Paper } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useReactMediaRecorder } from 'react-media-recorder';

const VideoQuestion = (props) => {
  const { handleSubmitAnswerButtonClick, payload } = props;
  const liveVideoRef = useRef(null);
  const [recordedVideoFile, setRecordedVideoFile] = useState(null);
  const theme = useTheme();

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl
  } = useReactMediaRecorder({
    video: true,
    onStop: (blobUrl, blob) => {
      const recordedFile = new File([blob], 'recorded.webm', { type: 'video/webm' });
      setRecordedVideoFile(recordedFile);
    }
  });

  const questionText = payload.content.question_text;

  useEffect(() => {
    if (liveVideoRef.current && previewStream) {
      liveVideoRef.current.srcObject = previewStream;
    }
    return () => {
      if (liveVideoRef.current) {
        liveVideoRef.current.srcObject = null;
      }
    };
  }, [previewStream]);

  const handleSubmitClick = () => {
    if (recordedVideoFile) {
      const answerResponse = {};
      handleSubmitAnswerButtonClick(answerResponse, recordedVideoFile);
      clearBlobUrl();
    }
  };

  const clearRecording = () => {
    clearBlobUrl();
    setRecordedVideoFile(null);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '80vh', padding: '20px' }}>
      {/* Question Section */}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontSize: '24px', fontWeight: 'bold', fontFamily: '"Noto Sans"', color: theme.palette.primary.main, textAlign: 'center' }}>
          Question: {questionText}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem sx={{
        height: 'auto',
        alignSelf: 'stretch',
        backgroundColor: theme.palette.divider,
        margin: '0 16px',
      }} />

      {/* Video Recording Section */}
      <Box sx={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {/* <Paper elevation={3} sx={{ padding: '20px', width: '100%', maxWidth: '900px', backgroundColor: theme.palette.primary.main }}> */}
        <Paper elevation={3} sx={{
          padding: '10px',
          width: '80%',
          maxWidth: '800px',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '16px' // This line adds rounded corners
        }}>
          <Box sx={{ marginBottom: '20px' }}>
            {status === 'idle' && (
              <Box
                sx={{
                  width: '100%',
                  aspectRatio: '16 / 9',
                  backgroundColor: '#f0f0f0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '4px'
                }}
              >
                <Typography variant="body1" color="textSecondary">
                  Press "Start Recording" button to record your answer.
                </Typography>
              </Box>
            )}
            <video
              ref={liveVideoRef}
              autoPlay
              muted
              playsInline
              style={{ width: '100%', aspectRatio: '16 / 9', display: status === 'recording' ? 'block' : 'none' }}
            />
            {status === 'stopped' && mediaBlobUrl && (
              <video controls style={{ width: '100%', aspectRatio: '16 / 9' }}>
                <source src={mediaBlobUrl} type="video/webm" />
              </video>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            {status === 'idle' && (
              <Button onClick={startRecording} variant="contained" color="primary">
                Start Recording
              </Button>
            )}
            {status === 'recording' && (
              <Button onClick={stopRecording} variant="contained" color="secondary">
                Stop Recording
              </Button>
            )}
            {status === 'stopped' && (
              <Button onClick={clearRecording} variant="contained" color="warning">
                Re Record
              </Button>
            )}
            <Button
              onClick={handleSubmitClick}
              variant="contained"
              color="primary"
              disabled={!recordedVideoFile}
            >
              Submit
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default VideoQuestion;