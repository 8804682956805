import { Grid, Paper, Typography, Button, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import fetchTests from "../APIMethods/fetchTests";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import TestLoadingComponent from "../LoadingComponents/TestLoadingComponent";

// Create a custom theme with Noto Sans for the body text and Sedan for the heading
const theme = createTheme({
    typography: {
        fontFamily: 'Noto Sans, Arial, sans-serif',
        body1: {
            fontFamily: 'Noto Sans, Arial, sans-serif',
            color: '#215F9A'
        },
        body2: {
            fontFamily: 'Noto Sans, Arial, sans-serif',
            color: '#215F9A'
        },
        h5: {
            fontFamily: 'Sedan, Arial, sans-serif', // Apply Sedan font to the heading
            fontWeight: 'bold', // Make the heading bold
            color: '#215F9A', // Blue color for the title
        },
        subtitle1: {
            fontFamily: 'Josefin Sans, Arial, sans-serif', // Default sans-serif font
            fontWeight: 'bold', // Make the DART text bold
            color: '#215F9A', // Blue color for the DART text
            fontSize: '2.2rem' // Adjust font size to be comparable to the logo height
        }
    },
});

const TestStart = (props) => {
    const { handleStartTestButtonClicked, testId, email } = props;

    const navigate = useNavigate();

    const { data: tests, error, isError, isLoading, isSuccess } = useQuery({
        queryKey: ['registeredTests'],
        queryFn: () => fetchTests(email)
    });


    if (isSuccess) {
        const exists = tests.some(test => Number(test.test_id) === Number(testId));
        if (exists === false) { navigate('/'); }
    }
    if (isError) {
        navigate('/register');
    }

    if (isLoading) return <TestLoadingComponent loadMessage={"Loading test.."} />;


    return (

        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    height: '100vh',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundImage: 'url(/background.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative'
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        position: 'relative',
                        zIndex: 1
                    }}
                >
                    <Grid container spacing={2} sx={{ justifyContent: 'left' }}>
                        <Grid item xs={12} md={8}>
                            <Paper elevation={3} sx={{
                                padding: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                height: 'auto',
                                width: '90vw',
                                position: 'relative'
                            }}>
                                {/* Blue Rectangle on the Left */}
                                <Box sx={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    width: '5px',
                                    backgroundColor: '#215F9A' // Blue color for the rectangle
                                }} />

                                {/* DART Logo and Text */}
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                    <img src="/dart-logo.png" alt="DART Logo" style={{ height: '30px', marginRight: '3px', marginBottom: '7px' }} />
                                    <Typography variant="subtitle1" component="span" >
                                        DART
                                    </Typography>
                                </Box>

                                {/* Headline */}
                                <Typography variant="h5" component="h2" gutterBottom>
                                    Test Guidelines
                                </Typography>

                                {/* Aligning all the text */}
                                <Box sx={{ textAlign: 'left', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'left', paddingTop: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: 2 }}>
                                        <img src="/time-icon.png" alt="Time Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Time Allotted:<br /> </strong> Total 100 minutes. Guesstimates: 22 mins, Business Cases: 42 mins, Break: 6 mins, Verbal: 30 mins. The timer starts when you click "Start Test."
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: 2 }}>
                                        <img src="/type-icon.png" alt="Question Type Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Question Types: <br /></strong> Multiple Choice, Subjective, Integer type questions and Audio based questions.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: 2 }}>
                                        <img src="/tech-req.png" alt="Tech Req Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Technical Requirements: <br /></strong> Use a stable internet connection and a modern web browser. Prepare headphones for audio-based questions.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: 2 }}>
                                        <img src="/test-env.png" alt="Test Env Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Test Environment: <br /></strong> Choose a quiet place free from distractions. Have scratch paper and a calculator ready if needed.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: 2 }}>
                                        <img src="/sec-gui.png" alt="Instruct Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Section Instructions: <br /></strong> Carefully read all instructions before starting each section. If you encounter any issues, note them and seek help afterward.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Second Section - Button at the Bottom */}
                    <Box sx={{ padding: 3, display: 'flex', justifyContent: 'center', marginTop: 0 }}>
                        <Button
                            variant="contained"
                            onClick={handleStartTestButtonClicked}
                            sx={{
                                backgroundColor: '#fdd835', // Yellow color
                                color: '#0d47a1', // Blue text color
                                padding: '5px 20px',
                                fontFamily: 'Sedan',
                                fontSize: '1rem',
                                fontWeight: 'bold', // Make button text bold
                                textTransform: 'none',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: '#0d47a1', // Blue background on hover
                                    color: '#fdd835', // Yellow text on hover
                                }
                            }}
                        >
                            Start Test
                        </Button>
                    </Box>
                </Box>

                {/* Overlay for background */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)', // Optional: add a semi-transparent overlay if needed
                        zIndex: 0,
                    }}
                />
            </Box>
        </ThemeProvider>
    );
}

export default TestStart;
