import axios from "axios";

const registerUser = async(queryParams) => {

    const url = process.env.REACT_APP_API_USER + '/register';
    const requestBody = queryParams
    const headers =
    {
        accept: '*/*',
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true'
    }
    const response = await axios.post(url, requestBody, headers);
    return response.data;

}

export default registerUser;
