import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

//TODO : find a better way to tell the environment.
const env = process.env.REACT_APP_ENVIRONMENT;



const ProtectedRoute = ({ component: Component, ...rest }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const params = useParams();

    console.log("protected route render")
    const authValidateUrl = env === "local" ? null : "https://api.dev.dartcouncil.org/auth/validate";


    useEffect(() => {
        console.log("baseUrl is : ");
        console.log(authValidateUrl);
        if (authValidateUrl) {
            axios.get('https://api.dev.dartcouncil.org/auth/validate', {
                withCredentials: true,

            })
                .then(response => {
                    if (response.status === 200) {
                        console.log(JSON.stringify(response.data));
                        setEmail(response.data.user.user.email);
                        setIsLoggedIn(true);
                    } else if (response.status === 403) {
                        window.location.assign('http://www.dartcouncil.org');

                    }
                })
                .catch(error => {
                    console.error('There has been a problem with fetch operation:', error);
                    window.location.assign('http://www.dartcouncil.org');
                });
        }
        else{
            setEmail("rai.avinash064@gmail.com");
            setIsLoggedIn(true);
        }
    }, []);

    return isLoggedIn ? <Component {...rest} {...params} email={email} /> : null;
}

export default ProtectedRoute;
