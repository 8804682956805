// import * as React from 'react';

import {React, useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CognitoIdentityProviderClient, SignUpCommand } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import

//TODO : check what this is about.
const defaultTheme = createTheme();

const clientId = '7lv65kgfbp3q6ogalksl6guikc';

const signUp = ( clientId, fullName, password, email) => {
  const client = new CognitoIdentityProviderClient({
    region: 'ap-southeast-2',
  });

  const command = new SignUpCommand({
    ClientId: clientId,
    Username: fullName,
    Password: password,
    UserAttributes: [
      {Name: "name", Value: fullName},
      {Name: "email", Value: email },
      {Name: "gender", Value: "male"},
      {Name: "birthdate", Value: "1996-03-22"},
      {Name: "phone_number", Value: "+919717572077"}
    ],
  });

  return client.send(command);
};

const handleSubmit = (event) => {
  event.preventDefault();
  const data = new FormData(event.currentTarget);
  const email = data.get('email');
  const password = data.get('password');
  const fullName = data.get('firstName') + crypto.randomUUID() + data.get('lastName');

  signUp(clientId, fullName, password, email);
};

export default function SignUp() {

  console.log("signUp rendered again ");


  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  useEffect(()=>{
      if(accessToken!=null){
        console.log("entered signUp useeffect");
        navigate('/');
      }
    },[]
  );
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}

//TODO
// 1. Add validation and messages for password requirements.
// Transfer to signIn page after successful signup 
// Add proper messages for why sign up failed.