import React, { useState } from 'react';
import { Grid, Typography, Button, Paper, Divider, Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SubjectiveQuestion = (props) => {

  const { handleSubmitAnswerButtonClick, payload } = props;

  console.log("entered subjective");
  const questionContent = payload != null ? payload.content : undefined;

  const questionText = questionContent != undefined ? questionContent.question_text : null;

  const [editorHtml, setEditorHtml] = useState('');

  const handleEditorChange = (html) => {
    setEditorHtml(html);
  };

  const handleSubmit = () => {
    const jsonString = JSON.stringify({ content: editorHtml });
    handleSubmitAnswerButtonClick(jsonString);
    setEditorHtml('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '60px' }}>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h5"
              sx={{ textAlign: 'left', fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '32px', ml: '20px' }}
            >
              Question
            </Typography>
            <Typography
              variant="h6"
              sx={{ textAlign: 'left', fontFamily: 'Noto Sans', fontWeight: 'normal', fontSize: '16px', marginTop: '10px', ml: '20px' }}
            >
              {questionText}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: 2, paddingLeft: 2 }}>
            <Paper elevation={0} style={{ padding: '10px', marginTop: '10px' }}>
              <ReactQuill
                value={editorHtml}
                onChange={handleEditorChange}
                modules={SubjectiveQuestion.modules}
                formats={SubjectiveQuestion.formats}
                placeholder="Type your answer here..."
                style={{ height: '200px', paddingLeft: '40px', paddingRight: '20px' }} // Added left and right padding
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '60px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ width: '150px', fontFamily: '"Noto Sans"' }}
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

// Quill modules and formats
SubjectiveQuestion.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    // ['link', 'image', 'video'],
    ['clean']
  ],
};

SubjectiveQuestion.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link'
];

export default SubjectiveQuestion;
