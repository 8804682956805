import axios from "axios";

const fetchTests = async (emailId) => {
    const queryParams = {
        email_id: emailId, // Properly assigned the email ID to the query parameter
    };
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_TEST}/registeredTests?email_id=${emailId}`);
        if (response.data.status === 'success') {
            return response.data.payload; // Successfully returning the data when the request is successful
        } else {
            console.error('Failed to fetch tests:', response.data.message); // Proper error handling for API errors
        }
    } catch (error) {
        console.error('Error fetching tests:', error); // Proper error handling for request errors
    }
}

export default fetchTests;
