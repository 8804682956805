import { createTheme } from '@mui/material/styles';

const AppTheme = createTheme({
  // Add your theme settings here
  palette: {
    primary: {
      main: '#326ba1', // Your specified primary color
    },
    secondary: {
      main :'#ffbf23',
    }
    // other theme settings
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {  // Target the root element of Button
          color: '#326ba1',  // Button text color
          fontWeight: 700,
          fontFamily: '"Noto Sans", "Roboto", "Helvetica", "Arial", sans-serif',
          textTransform: 'none'
          // '&:hover': {
          //   backgroundColor: '#265080', // Darken the primary color on hover
          // }
        },
        containedPrimary: { // Specific styles for buttons with the 'contained' variant and 'primary' color
          backgroundColor: '#ffbf23',
          // '&:hover': {
          //   backgroundColor: '#265080',
          // }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        // Target the text displayed in the Select input part
        select: {
          textAlign: 'left', 
          color: '#326ba1', // Use theme's primary color
          '&:focus': { // Style when the select is focused
            backgroundColor: 'transparent', // Optional: Remove background change on focus
          },
        },
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: '#326ba1', // Set the text color
          // textAlign: 'left', // Align text to the left
        }
      }
    },
    MuiOutlinedInput: {  // This targets the input part of an outlined TextField
      styleOverrides: {
        input: {
          color: '#326ba1', // Change the text color
          '&::placeholder': {  // Styling placeholder text
            color: 'primary.light'
          },
          textAlign: 'left', // Align text inside the input
        },
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main', // Change border color when focused
          },
        }
      }
    }
  },
  typography: {
    fontFamily: '"Josefin Sans", "Roboto", "Helvetica", "Arial", sans-serif', // Use custom font
    h1: {
      fontWeight: 300,
      fontFamily: '"Josefin Sans", "Roboto", "Helvetica", "Arial", sans-serif', // Use custom font
    },
    h2: {
      fontWeight: 400,
      color: '#326ba1', // Correctly access the theme's primary main color
      fontFamily: '"Sedan", "Times New Roman", "Helvetica", "Arial", sans-serif', // Use custom font
    },
    body1: {
      color: '#326ba1',
      fontSize: 12,
      fontFamily: '"Noto Sans", "Arial"',
    },
    h5 :{
      color: '#326ba1',
      fontFamily: '"Sedan", "Times New Roman"',
    },

    h6:{
      // color: 'primary.main',
      fontSize:'16px',
      fontFamily: '"Sedan", "Times New Roman"',
    },

    h4:{
      color: '#326ba1',
      fontSize:45,
      fontFamily: '"Sedan", "Josefin Sans", "Times New Roman"',
    },
    p:{
      color: '#326ba1',
      fontSize: 16,
      fontFamily: '"Sedan", "Josefin Sans", "Times New Roman"',
    }
  },

});

export default AppTheme


    // button: {
    //   color: '#ffbf23', // Correctly access the theme's primary main color
    //   textTransform: 'uppercase',
    //   fontWeight: 700,
    //   fontFamily: '"Josefin Sans", "Roboto", "Helvetica", "Arial", sans-serif', // Use custom font
    // },