import TopBar from './TopBar';
import TestList from './TestList';
import FooterSection from '../Footer/FooterSection';
import { useNavigate } from 'react-router-dom';
import './UserLandingPage.css';


function UserLandingPage(props) {
    const { email } = props;
    return (
        <div className="user-landing-page">
            <div className="content">
                <TopBar />
                <TestList email={email} />
            </div>
            <FooterSection />
        </div>
    )
}

export default UserLandingPage;