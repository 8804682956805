import axios from "axios";

const getProducts = async (country) => {
    const url = `${process.env.REACT_APP_API_PRODUCT}?country=${country}`;
    
    const headers = {
        'accept': '*/*',
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.get(url, { headers });
        return response.data.payload;
    } catch (error) {
        console.error("Error fetching products:", error);
        throw error;
    }
};

export default getProducts;