import axios from "axios";

const applyCoupon = async (couponCode, productId) => {
    const baseUrl = process.env.REACT_APP_API_PRODUCT;
    const url = `${baseUrl}/coupon?coupon_code=${encodeURIComponent(couponCode)}&product_id=${encodeURIComponent(productId)}`;
    
    const headers = {
        'accept': '*/*',
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.get(url, { headers });
        return response.data;
    } catch (error) {
        console.error("Error fetching products:", error);
        throw error;
    }
};

export default applyCoupon;