import React, {  memo } from 'react';
import { useTheme } from '@emotion/react';
import { Grid  } from '@mui/material';
import LogoSection from './LogoSection';
import TestTools from './TestTools';

const TestTopBar = (props) => {


    const theme = useTheme();

    const { payload, sectionGuidelines } = props;
    const timeLeft = payload.content.section_time_left;
    console.log(timeLeft);
    console.log("props in test top bar")
    console.log(props)

    return (
        <Grid container>
            {/* Top bar with the primary color */}
            <Grid item xs={12} sx={{ backgroundColor: theme.palette.primary.main, height: 6 }} />

            {/* Spacing */}
            <Grid item xs={12} sx={{ height: 0 }} />

            {/* Logo Section */}
            <Grid item xs={0.4} />
            <Grid item xs={2}>
                <LogoSection />
            </Grid>

            {/* Spacer to push the timer to the right */}
            <Grid item xs={7.6} />

            {/* Timer Countdown Component */}
            {(timeLeft !== undefined) && <Grid item xs={2}>
                <TestTools
                    timeLeft={timeLeft}
                    text="Time left"
                    handleMoveToNextSection={props.handleMoveToNextSection}
                    handleZoomIn={props.handleZoomIn}
                    handleZoomOut={props.handleZoomOut}
                    sectionGuidelines={sectionGuidelines} />
            </Grid>}
        </Grid>
    );
};
export default memo(TestTopBar, (prevProps, nextProps) => {
    // console.log("memo propssss");
    // console.log(prevProps);
    // console.log(nextProps);
    // prevProps.payload.content.section_time_left === nextProps.payload.content.section_time_left
});

// export default TestTopBar;
//TODO : move the below component to new file.
// const TestTools = (props) => {
//     const { text, timeLeft, handleMoveToNextSection, handleZoomIn, handleZoomOut, sectionGuidelines } = props;
//     console.log("sectionGuidelines");

//     console.log(sectionGuidelines);


//     const theme = useTheme();
//     const navigate = useNavigate(); // Hook for navigation
//     const [openDialog, setOpenDialog] = useState(false);
//     const [openCalculator, setOpenCalculator] = useState(false);
//     const [timeUpDialog, setTimeUpDialog] = useState(false);

//     const handleOpenDialog = () => {
//         setOpenDialog(true);
//     };

//     const handleCloseDialog = () => {
//         setOpenDialog(false);
//     };

//     const handleConfirmExit = () => {
//         navigate('/'); // Navigate to home route
//     };

//     const handleOpenCalculator = () => {
//         setOpenCalculator(true);
//     };

//     const handleCloseCalculator = () => {
//         setOpenCalculator(false);
//     };

//     const countdownRenderer = ({ minutes, seconds, completed }) => {
//         if (completed) {
//             setTimeUpDialog(true);
//             return <span>Time's up!</span>;
//         } else {
//             return <span>{minutes} minutes</span>;
//         }
//     };

//     const handleTimeUp = () => {
//         handleMoveToNextSection();
//         setTimeUpDialog(false);
//     };

//     return (
//         <Box sx={{ position: 'relative' }}>
//             {/* Close Icon with onClick handler to open dialog */}
//             <IconButton color="primary" onClick={handleOpenDialog} sx={{
//                 color: theme.palette.primary.main,
//                 backgroundColor: 'white',
//                 position: 'absolute',
//                 top: '0px',
//                 right: '160px',
//                 borderRadius: '50%',
//             }}>
//                 <CloseIcon />
//             </IconButton>

//             {/* Calculate Icon */}
//             <IconButton color="primary" onClick={handleOpenCalculator} sx={{
//                 color: theme.palette.primary.main,
//                 backgroundColor: 'white',
//                 position: 'absolute',
//                 top: '0px',
//                 right: '190px',
//                 borderRadius: '50%',
//             }}>
//                 <CalculateIcon />
//             </IconButton>
//             <IconButton color="primary" onClick={handleZoomIn} sx={{
//                 color: theme.palette.primary.main,
//                 backgroundColor: 'white',
//                 position: 'absolute',
//                 top: '0px',
//                 right: '220px',
//                 borderRadius: '50%',
//             }}>
//                 <ZoomIn />
//             </IconButton>
//             <IconButton color="primary" onClick={handleZoomOut} sx={{
//                 color: theme.palette.primary.main,
//                 backgroundColor: 'white',
//                 position: 'absolute',
//                 top: '0px',
//                 right: '250px',
//                 borderRadius: '50%',
//             }}>
//                 <ZoomOut />
//             </IconButton>
//             <Tooltip title={sectionGuidelines} arrow
//                 sx={{
//                     '& .MuiTooltip-tooltip': {
//                         backgroundColor: '#333',  // Dark background for contrast
//                         color: '#fff',  // White text for readability
//                         fontSize: '0.875rem',  // Comfortable font size
//                         padding: '8px 12px',  // Adequate padding for content
//                     }
//                 }}>
//                 <IconButton
//                     sx={{
//                         color: theme.palette.primary.main,
//                         backgroundColor: 'white',
//                         position: 'absolute',
//                         top: '0px',
//                         right: '280px',
//                         borderRadius: '50%',
//                     }}
//                 >
//                     <InfoOutlinedIcon />
//                 </IconButton>
//             </Tooltip>

//             {/* Timer Box */}
//             <Box sx={{
//                 position: 'absolute',
//                 top: 0,
//                 right: 0,
//                 padding: '0px 0px',
//                 height: '35px',
//                 backgroundColor: theme.palette.primary.main,
//                 color: 'white',
//                 fontWeight: 'bold',
//                 borderRadius: '0 0 0 5px',
//                 display: 'flex',
//                 alignItems: 'center',
//                 alignContent: 'center',
//                 minWidth: '150px', // Fixing the width of the timer box
//             }}>
//                 <Typography variant="h6" sx={{ fontWeight: 'regular', fontSize: '14px', marginLeft: 2.3, fontFamily: '"Noto Sans"' }}>
//                     {text}: <Countdown key={timeLeft} date={Date.now() + timeLeft} renderer={countdownRenderer} />
//                 </Typography>
//             </Box>

//             <Dialog
//                 open={openDialog}
//                 onClose={handleCloseDialog}
//                 aria-labelledby="alert-dialog-title"
//                 aria-describedby="alert-dialog-description"
//             >
//                 <DialogContent>
//                     <DialogContentText id="alert-dialog-description">
//                         Do you want to exit the test?
//                     </DialogContentText>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleCloseDialog}>No</Button>
//                     <Button onClick={handleConfirmExit} autoFocus>
//                         Yes
//                     </Button>
//                 </DialogActions>
//             </Dialog>

//             {/* Calculator Popup */}
//             <Dialog
//                 open={openCalculator}
//                 onClose={handleCloseCalculator}
//                 aria-labelledby="calculator-dialog-title"
//                 BackdropComponent={() => null}
//                 PaperProps={{
//                     style: {
//                         position: 'fixed',
//                         bottom: 0,
//                         right: 0,
//                         margin: 0,
//                         maxWidth: '100%',
//                         maxHeight: '50vh',
//                     }
//                 }}
//             >
//                 <IconButton
//                     onClick={handleCloseCalculator}
//                     sx={{ position: 'absolute', right: 8, top: 8 }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//                 <Calculator />
//             </Dialog>
//             <Dialog
//                 open={timeUpDialog}
//                 aria-labelledby="time-up-dialog-title"
//                 aria-describedby="time-up-dialog-description"
//             >
//                 <DialogContent>
//                     <DialogContentText id="time-up-dialog-description">
//                         Time's up! Moving to the next section.
//                     </DialogContentText>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleTimeUp} autoFocus>
//                         OK
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </Box>
//     );

// };
