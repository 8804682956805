import React, { useState, useEffect } from "react";
import { useMutation } from '@tanstack/react-query';
import startSectionAPI from "../APIMethods/startSectionAPI";
import startTestAPI from "../APIMethods/startTestAPI";
import submitAnswerAPI from "../APIMethods/submitAnswerAPI";
import TestStart from "./TestStart";
import SectionView from "./SectionVIew";
import QuestionContainer from "./QuestionContainer";
import TestTopBar from "../TestInterface/TestTopBar";
import EndOfTestComponent from "./EndOfTestComponent";
import { useQueryClient } from '@tanstack/react-query';
import TestLoadingComponent from "../LoadingComponents/TestLoadingComponent";
import TopBar from "../TestInterface/TopBar";
import nextSectionAPI from "../APIMethods/nextSectionAPI";
import './TestContainer.css';

const TestContainer = (props) => {

    const queryClient = useQueryClient();
    const { testId, email } = props;
    const [permissionsGranted, setPermissionsGranted] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(100);

    const [sectionGuidelines, setSectionGuidelines] = useState('');


    const [isPageVisible, setIsPageVisible] = useState(true);
    const handleZoomIn = () => {
        setZoomLevel(prevZoom => Math.min(prevZoom + 10, 200)); // Increase by 10%, max 200%
    };

    const handleZoomOut = () => {
        setZoomLevel(prevZoom => Math.max(prevZoom - 10, 50)); // Decrease by 10%, min 50%
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setIsPageVisible(false);
                alert("Warning: You have switched away from the test tab. Please return immediately to avoid test invalidation.");
            } else {
                setIsPageVisible(true);
            }
        };

        const handleBlur = () => {
            alert("Warning: You have clicked away from the test window. Please return focus to the test immediately.");
        };

        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = "Are you sure you want to leave? This may invalidate your test.";
        };

        const preventBackNavigation = (event) => {
            event.preventDefault();
            window.history.forward();
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("blur", handleBlur);
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', preventBackNavigation);

        window.history.pushState(null, null, window.location.pathname);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.pathname);
        };

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("blur", handleBlur);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', preventBackNavigation);
        };
    }, []);


    useEffect(() => {
        handleMediaPermissions();
    }, []);

    useEffect(() => {
        const preventBackNavigation = (event) => {
            event.preventDefault();
            window.history.forward();
        };

        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', preventBackNavigation);
        window.addEventListener('beforeunload', handleBeforeUnload);

        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.pathname);
        };

        return () => {
            window.removeEventListener('popstate', preventBackNavigation);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);



    const requestPermissions = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            stream.getTracks().forEach(track => track.stop());
            setPermissionsGranted(true);
        } catch (error) {
            console.error("Permission request error:", error);
            alert("Camera and microphone permissions are required to start the test.");
        }
    };


    const handleMediaPermissions = () => {
        requestPermissions();
    };


    const [viewType, setViewType] = useState('TEST_INTRO_PAGE');
    const [payload, setPayload] = useState(undefined);

    const handleViewChange = (data) => {
        setPayload(data.payload);
        const dataType = data.payload.content_type;
        setViewType(dataType);

        if (dataType === 'SECTION' && data.payload.content && data.payload.content.section_guidelines) {
            console.log("testing section guidelines");
            console.log(data)
            setSectionGuidelines(data.payload.content.section_guidelines);
            console.log(sectionGuidelines);
        }
    };

    const startTestMutation = useMutation({
        mutationFn: () => startTestAPI(email, testId),
        onSuccess: (response) => {
            handleViewChange(response.data);
            queryClient.invalidateQueries(['registeredTests']);
        },
    });

    const startSectionMutation = useMutation({
        mutationFn: () => startSectionAPI(email, testId),
        onSuccess: (response) => {
            handleViewChange(response.data);
        },
    });

    const submitAnswerMutation = useMutation({
        mutationFn: ({ answerResponse, questionType, recordedVideo }) =>
            submitAnswerAPI(email, answerResponse, testId, questionType, recordedVideo),
        onSuccess: (response) => {
            handleViewChange(response.data);
        },
    });

    const handleSubmitAnswerButtonClick = (answerResponse, recordedVideoFile) => {
        submitAnswerMutation.mutate({
            answerResponse,
            questionType: payload.content.question_type,
            recordedVideo: recordedVideoFile,
        });
    };

    const nextSectionMutation = useMutation({
        mutationFn: () => nextSectionAPI(email, testId),
        onSuccess: (response) => {
            handleViewChange(response.data);
        },
        onError: (error) => {
            console.error('Error moving to next section:', error);
        }
    });

    const handleStartTestButtonClicked = () => {
        if (permissionsGranted) {
            startTestMutation.mutate();
        }
        //TODO : add the else statement.
        // else {
        //     setShowFullScreenPrompt(true);
        // }
    };

    const handleStartSectionButtonClicked = () => {
        startSectionMutation.mutate();
    };

    const handleMoveToNextSection = () => {
        nextSectionMutation.mutate();
    };

    const renderComponent = () => {
        if (startTestMutation.isPending) {
            return <TestLoadingComponent loadMessage={"Preparing test.."} />
        }
        else if (startSectionMutation.isPending) {
            return <TestLoadingComponent loadMessage={"Loading Section.."} />
        }
        else if (submitAnswerMutation.isPending) {
            return <TestLoadingComponent loadMessage={"Submitting response.."} />
        }
        else if (nextSectionMutation.isPending) {
            return <TestLoadingComponent loadMessage={"Moving to Next Section.."} />
        }

        switch (viewType) {
            case 'TEST_INTRO_PAGE':
                return <TestStart handleStartTestButtonClicked={handleStartTestButtonClicked} testId={testId} email={email} />;
            case 'SECTION':
                return <SectionView payload={payload} handleStartSectionButtonClicked={handleStartSectionButtonClicked} />;
            case 'QUESTION':
                return <QuestionContainer handleSubmitAnswerButtonClick={handleSubmitAnswerButtonClick} payload={payload} />;
            case 'END_OF_TEST':
                return <EndOfTestComponent testId={testId} />;
            default:
                return null;
        }
    };

    return (
        <div>
            {(viewType !== 'TEST_INTRO_PAGE' && viewType !== 'LOADING' && viewType !== 'END_OF_TEST')
                &&
                <TestTopBar
                    payload={payload}
                    handleMoveToNextSection={handleMoveToNextSection}
                    handleZoomIn={handleZoomIn}
                    handleZoomOut={handleZoomOut}
                    sectionGuidelines={sectionGuidelines}
                />}
            {(viewType === 'END_OF_TEST') && <TopBar />}
            <div style={{ zoom: `${zoomLevel}%` }}>

                {renderComponent()}
            </div>
        </div>
    );
};

export default TestContainer;

// export default React.memo(TestContainer);
// Using React.memo will not let the re-renders that happens triggered by useMutation hook state changes when the state of the hook goes to loading and then to success.

// const handleFullScreenCancel = () => {
//     setShowFullScreenPrompt(false);
//     alert("You need to be in full screen mode to start the test.");
// };
// useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (document.hidden) {
//         setIsPageVisible(false);
//         // You can add additional actions here, like pausing the test or showing a warning
//         alert("Please do not switch tabs or minimize the browser during the test.");
//       } else {
//         setIsPageVisible(true);
//       }
//     };

//     const handleBlur = () => {
//       setIsPageVisible(false);
//       // You can add additional actions here
//       alert("Please keep this tab focused during the test.");
//     };

//     document.addEventListener("visibilitychange", handleVisibilityChange);
//     window.addEventListener("blur", handleBlur);

//     return () => {
//       document.removeEventListener("visibilitychange", handleVisibilityChange);
//       window.removeEventListener("blur", handleBlur);
//     };
//   }, []);


{/* {showFullScreenPrompt && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-header">
                            <h5 className="modal-title">Enter Full Screen</h5>
                            <button className="close-button" onClick={handleFullScreenCancel}>&times;</button>
                        </div>
                        <div className="modal-body">
                            Please enter full screen mode to start the test.
                        </div>
                        <div className="modal-footer">
                            <button className="modal-button" onClick={handleFullScreenCancel}>Cancel</button>
                            <button className="modal-button" onClick={handleFullScreenConfirm}>Enter Full Screen</button>
                        </div>
                    </div>
                </div>
            )} */}

                // const handleFullScreenConfirm = () => {
    //     // requestFullScreen();
    //     // setShowFullScreenPrompt(false);
    //     requestPermissions();
    // };

        // useEffect(() => {
    //     const handleFullScreenChange = () => {
    //         if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
    //             alert("You have exited full screen mode. Please return to full screen mode to continue the test.");
    //             setShowFullScreenPrompt(true);
    //         }
    //     };

    //     document.addEventListener("fullscreenchange", handleFullScreenChange);
    //     document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    //     document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    //     document.addEventListener("msfullscreenchange", handleFullScreenChange);

    //     return () => {
    //         document.removeEventListener("fullscreenchange", handleFullScreenChange);
    //         document.removeEventListener("mozfullscreenchange", handleFullScreenChange);
    //         document.removeEventListener("webkitfullscreenchange", handleFullScreenChange);
    //         document.removeEventListener("msfullscreenchange", handleFullScreenChange);
    //     };
    // }, []);
    // const [isFullScreen, setIsFullScreen] = useState(false);
    // const [showFullScreenPrompt, setShowFullScreenPrompt] = useState(true);

        // const requestFullScreen = () => {
    //     const element = document.documentElement;
    //     if (element.requestFullscreen) {
    //         element.requestFullscreen().then(() => setIsFullScreen(true));
    //     } else if (element.mozRequestFullScreen) {
    //         element.mozRequestFullScreen().then(() => setIsFullScreen(true));
    //     } else if (element.webkitRequestFullscreen) {
    //         element.webkitRequestFullscreen().then(() => setIsFullScreen(true));
    //     } else if (element.msRequestFullscreen) {
    //         element.msRequestFullscreen().then(() => setIsFullScreen(true));
    //     }
    // };