import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Divider, Typography, Box, Paper, Button, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import fetchTests from '../APIMethods/fetchTests'
import { useQuery } from '@tanstack/react-query';
import { useTheme } from '@mui/material';
import LoadingComponent from '../LoadingComponents/LoadingComponent';


const TestList = (props) => {
    const { email } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    const { data: tests, error, isError, isLoading } = useQuery({
        queryKey: ['registeredTests'],
        queryFn: () => fetchTests(email)
    });

    return (

        <Box sx={{ margin: 11, marginTop: 4, width: '90%', bgcolor: 'background.paper', justifyContent: 'space-between', alignItems: 'center' }}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={8}>
                    <Typography variant="h5" gutterBottom component="div" sx={{ color: theme.palette.primary.main, textAlign: 'left', ml: 2, fontSize: 30, fontfamily: '"Noto Sans"', fontWeight: 'bold' }}>
                        Registered Tests
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={() => navigate('/register')} sx={{ height: 40, ml: 18, mt: 1, fontSize: 15 }}>
                        Register for new DART Test
                    </Button>
                </Grid>
            </Grid>

            <Paper elevation={3}>

                <Box sx={{ ml: 1, mt: 4, maxHeight: 300, overflow: 'auto', textAlign: 'left' }}>
                    {isLoading ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <LoadingComponent loadMessage={"fetching tests.."} />
                        </Box>
                    ) :
                        isError ? (
                            <Box sx={{ height: '10vh', alignContent: 'center' }}>
                                <Typography color="error" variant='h5'>You haven't registered for a test yet. Please register for a test using the button above</Typography>
                            </Box>
                        ) :
                            (
                                <List>
                                    {tests?.map((test, index) => (
                                        <React.Fragment key={test.test_id}>
                                            <ListItem sx={{ position: 'relative', paddingLeft: '20px' }}>
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        height:'auto',
                                                        left: 0,
                                                        top: 0,
                                                        bottom: 0,
                                                        width: '8px',
                                                        backgroundColor: theme.palette.primary.main
                                                    }}
                                                />
                                                <ListItemText primaryTypographyProps={{
                                                    variant: 'h5',
                                                    style: { fontSize: 30, fontWeight: 'bold' }
                                                }}
                                                    secondaryTypographyProps={{
                                                        variant: 'h5',
                                                        style: { fontSize: 17, marginLeft: 28 }
                                                    }}
                                                    // primary={`${index + 1}. ${test.subject}`}
                                                    primary={"General Management"}
                                                // secondary={`Industry 1: ${test.industry1}, Industry 2: ${test.industry2}`}
                                                />
                                                <Button
                                                    variant="contained"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href = `/test/${test.test_id}`;
                                                    }}
                                                    sx={{ marginLeft: 'auto', backgroundColor: '#ffffff', border: '1px solid' }}
                                                >
                                                    Go to Test
                                                </Button>
                                            </ListItem>
                                            {index < tests.length - 1 && <Divider />}
                                        </React.Fragment>
                                    ))}
                                </List>
                            )}
                </Box>
            </Paper>
        </Box>

    );
}

export default TestList;
