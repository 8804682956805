import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Typography } from '@mui/material';
import Calculate from './Calculate';

const Calculator = () => {
  const [calculatorObj, setCalculatorObj] = useState({
    total: null,
    next: null,
    operation: null,
  });

  const updateCalculatorObj = (e) => {
    const buttonName = e.currentTarget.name;
    const calculateObj = Calculate(calculatorObj, buttonName);
    setCalculatorObj(calculateObj);
  };

  const handleKeyPress = (e) => {
    const keyMap = {
      '0': '0', '1': '1', '2': '2', '3': '3', '4': '4',
      '5': '5', '6': '6', '7': '7', '8': '8', '9': '9',
      '+': '+', '-': '-', '*': 'x', '/': '÷', '=': '=', 'Enter': '=',
      '.': '.', 'Backspace': 'AC', 'Escape': 'AC',
    };

    // When enter is pressed it fixed unwanted 1 
    if (e.key === 'Enter') {
      e.preventDefault();
    }

    if (keyMap[e.key]) {
      const calculateObj = Calculate(calculatorObj, keyMap[e.key]);
      setCalculatorObj(calculateObj);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [calculatorObj]);

  const buttonConfig = [
    ['AC', '+/-', '%', '÷'],
    ['7', '8', '9', 'x'],
    ['4', '5', '6', '-'],
    ['1', '2', '3', '+'],
    ['0', '.', '=']
  ];

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" sx={{ backgroundColor: '#ffffff', color: '#326ba1', fontFamily: 'Josefin sans', fontSize:'35px' }}>
        {calculatorObj.total || "0"}
        {calculatorObj.operation}
        {calculatorObj.next}
      </Typography>
      <Grid container spacing={0.5}>
        {buttonConfig.map((row, rowIndex) => (
          <Grid key={rowIndex} container item xs={12} spacing={0.5}>
            {row.map((button, buttonIndex) => {
              const isZeroButton = button === '0';
              return (
                <Grid key={buttonIndex} item xs={isZeroButton ? 6 : 3}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    name={button}
                    onClick={updateCalculatorObj}
                  >
                    {button}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Calculator;