import Operate from './Operate';

function isNumber(item) {
  return !!item.match(/[0-9]+/);
}

export default function Calculate(obj, buttonName) {
  if (buttonName === 'AC') {
    return {
      total: null,
      next: null,
      operation: null,
    };
  }

  if (isNumber(buttonName)) {
    if (obj.operation) {
      if (!obj.next || obj.next === '0') {
        return { ...obj, next: buttonName };
      } else {
        return { ...obj, next: obj.next + buttonName };
      }
    } else {
      if (!obj.total || obj.total === '0') {
        return { ...obj, total: buttonName };
      } else {
        return { ...obj, total: obj.total + buttonName };
      }
    }
  }

  if (buttonName === '.') {
    if (obj.operation) {
      if (!obj.next) {
        return { ...obj, next: '0.' };
      } else if (obj.next.includes('.')) {
        return { ...obj };
      } else {
        return { ...obj, next: `${obj.next}.` };
      }
    } else {
      if (!obj.total) {
        return { ...obj, total: '0.' };
      } else if (obj.total.includes('.')) {
        return { ...obj };
      } else {
        return { ...obj, total: `${obj.total}.` };
      }
    }
  }

  if (buttonName === '=') {
    if (obj.total && obj.next && obj.operation) {
      let result = Operate(obj.total, obj.next, obj.operation);
      result = parseFloat(result).toFixed(2);  // Round result to two decimal places
      return {
        total: result,
        next: '',
        operation: null,
      };
    }
    return obj; // Return current state if not enough operands or operation
  }

  if (buttonName === '+/-') {
    if (obj.next) {
      return { ...obj, next: (-1 * parseFloat(obj.next)).toString() };
    }
    if (obj.total) {
      return { ...obj, total: (-1 * parseFloat(obj.total)).toString() };
    }
    return obj; 
  }

  if (['+', '-', 'x', '÷', '%'].includes(buttonName)) {
    if (obj.total && obj.next && obj.operation) {
      let result = Operate(obj.total, obj.next, obj.operation);
      result = parseFloat(result).toFixed(2);  
      return {
        total: result,
        next: '',
        operation: buttonName,
      };
    } else if (obj.total && !obj.next) {
      return { ...obj, operation: buttonName };
    } else if (obj.total && obj.next && !obj.operation) {
      return { ...obj, operation: buttonName };
    }
    return obj; 
  }

  return obj; // Return current state for unrecognized buttonName
}