
import React from 'react';
import { Grid, Box, Button, Divider } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '../QuestionAsset/Table';
import parse from 'html-react-parser';


const MCQQuestion = (props) => {

  const [isResponseValid, setIsResponseValid] = useState(true); // Initialize the state


  const theme = useTheme();
  const { handleSubmitAnswerButtonClick, payload } = props;

  const questionContent = payload != null ? payload.content : undefined;
  const [selectedOption, setSelectedOption] = useState([]);

  const optionsData = questionContent != undefined ? questionContent.options : null;
  const context = questionContent != undefined ? questionContent.context : null;
  const questionText = questionContent != undefined ? questionContent.question_text : null;
  const tables = questionContent != undefined ? questionContent.table_data?.tables : null;

  const boxRef = React.useRef(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  React.useLayoutEffect(() => {
    if (boxRef.current) {
      setIsOverflowing(boxRef.current.scrollHeight > boxRef.current.clientHeight);
    }
  }, [context, tables]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedOption((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(value)) {
        return prevSelectedOptions.filter((option) => option !== value);
      } else {
        return [...prevSelectedOptions, value];
      }
    });
  };
  const handleSubmitAnswer = (selectedOption) => {
    if (selectedOption.length == 0) {
      setIsResponseValid(false);
    }
    else {
      handleSubmitAnswerButtonClick(selectedOption);
      setSelectedOption([]);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} container sx={{ height: 50 }}></Grid>
      <Grid container spacing={2}>
        <Grid item xs={1} />

        {/* Context on the left side */}
        <Grid item xs={4}>
          <Box sx={{ height: 'auto', mr: 2 }}>
            <Typography variant="h5" sx={{ textAlign: 'right', fontWeight: 'bold', fontSize: '24px', fontFamily: '"Noto Sans"' }}>
              Context
            </Typography>
            <Divider sx={{ background: theme.palette.primary.main, borderBottomWidth: 1, mt: -0.5 }} />
            <Box sx={{
              maxHeight: '75vh',
              overflowY: 'auto',
              mt: 3,
              pr: 2,
              boxSizing: 'border-box', // Ensures padding is included in height calculation

              '&::-webkit-scrollbar': {
                width: '6px', // Adjust thickness of the scrollbar
              },
              '&::-webkit-scrollbar-track': {
                background: 'rgba(0,0,0,0.1)', // Color of the track
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(0,0,0,0.5)', // Color of the scrollbar thumb
                borderRadius: '10px', // Round the corners
              },
              '&:not(:hover)::-webkit-scrollbar-thumb': {
                background: 'transparent',
              },
            }}>
              <Typography gutterBottom variant="body1" sx={{ textAlign: 'justify', fontSize: '14px' }}>
                {parse(context)}
              </Typography>

              <Grid container spacing={2}>
                {tables && tables.map((tableData, index) => (
                  <Grid item xs={11} key={index}>
                    <Table tableData={tableData} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Divider orientation="vertical" flexItem sx={{
          margin: theme.spacing(0, 2),
          height: '100%',
          backgroundColor: theme.palette.grey[300],
        }} />

        <Grid item xs={6}>
          <Box sx={{ height: 'auto' }}>
            <Typography variant="h5" sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: '24px', fontFamily: '"Noto Sans"' }}>
              Question
            </Typography>
            <Divider sx={{ background: theme.palette.primary.main, borderBottomWidth: 1, mt: -0.5 }} />
            <Typography gutterBottom variant="body1" sx={{ mt: 3, textAlign: 'left', fontSize: '14px' }}>
              {questionText}
            </Typography>

            <Box sx={{ height: 'auto', ml: '20px', mt: '15px', alignItems: 'center', alignContent: 'center' }}>
              <FormControl>
                {optionsData && optionsData.options ? (
                  optionsData.options.map((option) => (
                    <FormControlLabel
                      key={option.optionCode}
                      value={option.optionCode}
                      control={<Checkbox onChange={handleCheckboxChange} />}
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: '14px',
                            mt: '0px',
                            mb: '0px',
                            textAlign: 'left',
                            color: selectedOption.includes(option.optionCode) ? theme.palette.secondary.main : theme.palette.primary.main,
                            fontWeight: selectedOption.includes(option.optionCode) ? 'bold' : 'normal'
                          }}>
                          {option.optionValue}
                        </Typography>
                      }
                      checked={selectedOption.includes(option.optionCode)}
                    />
                  ))
                ) : (
                  <div>No options available</div>
                )}
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, minHeight: '80px' }}>
            <Box sx={{ height: '24px', mb: 1 }}>
              {!isResponseValid && (
                <Typography variant="body2" color="error">
                  Please select at least one option to submit the answer
                </Typography>
              )}
            </Box>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: 300 }}
              onClick={() => {
                handleSubmitAnswer(selectedOption);
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Submit
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid xs={12} container sx={{ height: 10 }}></Grid>
    </Grid>
  )
}
export default MCQQuestion;


{/* <Box
              ref={boxRef}
              sx={{
                maxHeight: '900px',
                overflowY: isOverflowing ? 'scroll' : 'hidden',
                mt: 3,
                pr: 2,
                boxSizing: 'border-box',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'rgba(0,0,0,0.1)',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(0,0,0,0.5)',
                  borderRadius: '10px',
                },
              }}
            >
              <Typography gutterBottom variant="body1" sx={{ textAlign: 'justify', fontSize: '14px' }}>
                {parse(context)}
              </Typography>
              <Grid container spacing={2}>
                {tables && tables.map((tableData, index) => (
                  <Grid item xs={11} key={index}>
                    <Table tableData={tableData} />
                  </Grid>
                ))}
              </Grid>
            </Box> */}

            {/* <Box sx={{
              position: 'relative',
              maxHeight: '900px',
              mt: 3,
              pr: 2,
              boxSizing: 'border-box',
              overflow: 'hidden',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                width: '6px',
                height: '100%',
                background: 'transparent',
              },
              '&:hover::after': {
                background: 'rgba(0,0,0,0.1)',
              },
              '& > div': {
                maxHeight: '100%',
                overflowY: 'auto',
                paddingRight: '6px', // Width of the scrollbar
                marginRight: '-6px', // Offset the padding
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(0,0,0,0.5)',
                  borderRadius: '10px',
                },
                '&:not(:hover)::-webkit-scrollbar-thumb': {
                  background: 'transparent',
                },
              },
            }}>
              <div>
                <Typography gutterBottom variant="body1" sx={{ textAlign: 'justify', fontSize: '14px' }}>
                  {parse(context)}
                </Typography>
                <Grid container spacing={2}>
                  {tables && tables.map((tableData, index) => (
                    <Grid item xs={11} key={index}>
                      <Table tableData={tableData} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Box> */}