import React from 'react';
import { Grid, Link } from '@mui/material';
import { useTheme } from '@emotion/react';
import LogoSection from './LogoSection';

const TopBar = () => {
    const theme = useTheme();
    return (


        <Grid container >
            <Grid item xs={12} sx={{ backgroundColor: theme.palette.primary.main, height: 8 }} />

            <Grid item xs={12} sx={{ height: 25 }} />

            <Grid item xs={0.7} />
            <Grid item xs={2}>
                <LogoSection/>
            </Grid>
            <Grid item xs={3.7} />

            <Grid item xs={5}>
                <Grid container spacing={3}>
                    <Grid item>
                        <Link href='https://www.dartcouncil.org/about' underline="none" variant="h6" >About</Link>
                    </Grid>
                    <Grid item>
                        <Link href='https://www.dartcouncil.org/resources' underline="none" variant="h6" >Resources</Link>
                    </Grid>
                    
                    <Grid item>
                        <Link href='https://test.dartcouncil.org/reports' underline="none" variant="h6" >My Reports</Link>
                    </Grid>
                    <Grid item>
                        <Link href='https://www.dartcouncil.org/faqs' underline="none" variant="h6">FAQs</Link>
                    </Grid>
                    <Grid item>
                        <Link href='https://www.dartcouncil.org/blog' underline="none" variant="h6">Blog</Link>
                    </Grid>
                    <Grid item>
                        <Link href='https://www.dartcouncil.org/contact-8' underline="none" variant="h6">Contact</Link>
                    </Grid>
                    <Grid item>
                        <Link href='https://login.dartcouncil.org/logout' underline="none" variant="h6">Logout      </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TopBar;

