import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';

const LoadingComponent = (props) => {

    const { loadMessage } = props
    return (
        <Stack
            direction="column"
            alignItems="center"
            spacing={2}
        >
            <CircularProgress />
            <Typography variant="body1" sx={{ marginTop: 2 }}>
                {loadMessage}
            </Typography>
        </Stack>
    );
}

export default LoadingComponent;
