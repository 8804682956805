import React from 'react';
import { Grid, Box, Button, Divider } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MCQQuestion from './MCQQuestion';
import VideoQuestion from './VideoQuestion.jsx';
import SubjectiveQuestion from './SubjectiveQuestion';
import NumericQuestion from './NumericQuestion';



const QuestionContainer = (props) => {
  const { handleSubmitAnswerButtonClick, payload } = props;


  const questionType = payload.content.question_type;
  console.log("questionType " + questionType);

  const renderQuestionComponent = () => {
    switch (questionType) {
      case 'MC_MCQ':
        return <MCQQuestion payload={payload} handleSubmitAnswerButtonClick={handleSubmitAnswerButtonClick} />
      case 'VIDEO':
        return <VideoQuestion payload={payload} handleSubmitAnswerButtonClick={handleSubmitAnswerButtonClick} />
      case 'SUBJECTIVE':
        return <SubjectiveQuestion payload={payload} handleSubmitAnswerButtonClick={handleSubmitAnswerButtonClick} />
      case 'NUMERIC':
        return <NumericQuestion payload={payload} handleSubmitAnswerButtonClick={handleSubmitAnswerButtonClick} />
      default:
        return <div>temp</div>;
    }
  };

  return (
    <div>
      {renderQuestionComponent()}
    </div>
  );
  return (
    <div>

      <MCQQuestion payload={payload} handleSubmitAnswerButtonClick={handleSubmitAnswerButtonClick}>

      </MCQQuestion>


    </div>
  );
}

export default QuestionContainer;
