import React, { useState, useEffect } from 'react';
import { Box, Paper, MobileStepper, Slide, useMediaQuery, useTheme } from '@mui/material';

// import Slide1 from '/Slide1.png';
// import Slide2 from '/Slide2.png';
// import Slide3 from '/Slide3.png';

// const images = [Slide1, Slide2, Slide3];


const images = [
    '/Slide1.png',
    '/Slide2.png',
    '/Slide3.png',
];

const Carousel = () => {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = images.length;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
        }, 4000);

        return () => clearInterval(timer);
    }, [maxSteps]);

    return (
        <Box sx={{
            maxWidth: '100%',
            width: '100%',
            position: 'relative',
            paddingTop: '56.25%', // 16:9 Aspect Ratio (9 / 16 = 0.5625)
            overflow: 'hidden'
        }}>
            {images.map((image, index) => (
                <Slide direction="left" in={activeStep === index} mountOnEnter unmountOnExit key={index}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: '16px', // Curved edges
                            overflow: 'hidden', // Ensures the image respects the border radius
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                            src={image}
                            alt={`Slide ${index + 1}`}
                        />
                    </Box>
                </Slide>
            ))}
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                    '& .MuiMobileStepper-dot': {
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    },
                    '& .MuiMobileStepper-dotActive': {
                        backgroundColor: 'white',
                    },
                }}
            />
        </Box>
    );
};


export default Carousel;