import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
    TextField,
    Button,
    Grid,
    Paper,
    Typography,
    Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import './EndOfTestComponent.css';
import submitFeedbackAPI from "../APIMethods/submitFeedbackAPI";
import TestLoadingComponent from '../LoadingComponents/TestLoadingComponent';
import { useNavigate } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
}));

const EndOfTestComponent = ({ testId }) => {

    const navigate = useNavigate();

    const [feedbackText, setFeedbackText] = useState('');

    const submitFeedbackMutation = useMutation({
        mutationFn: async (feedback) => {
            const start = Date.now();
            const result = await submitFeedbackAPI(testId, feedback);
            const elapsed = Date.now() - start;
            if (elapsed < 1200) await new Promise(r => setTimeout(r, 1200 - elapsed));
            return result;
        },
        onSuccess: () => navigate("/"),
        onError: (error) => console.error('Error submitting feedback', error)
    });
    const handleSubmitFeedback = () => {
        submitFeedbackMutation.mutate(feedbackText);
    };

    if (submitFeedbackMutation.isPending) {
        return <TestLoadingComponent loadMessage={"Submitting Feedback.."}/>
    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={8}>
                <StyledPaper elevation={3}>
                    <Typography variant="h5" gutterBottom>
                        Thank you for giving the test! Please provide your valuable feedback.
                    </Typography>
                    <TextField
                        className="fbox"
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder="Please provide your feedback here"
                        value={feedbackText}
                        onChange={(e) => setFeedbackText(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmitFeedback}
                            fullWidth
                        >
                            Submit Feedback
                        </Button>
                    </Box>
                </StyledPaper>
            </Grid>
        </Grid>
    );
};

export default EndOfTestComponent;