import axios from "axios";

const submitFeedbackAPI = async (testId, feedbackText) => {
    const url = process.env.REACT_APP_API_FEEDBACK + '/submit';
    const requestBody = {
        testId: testId,
        subject: 'Operations',
        feedbackText: feedbackText
    };
    const headers = {
        accept: '*/*',
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true'
    };
    const response = await axios.post(url, requestBody, { headers });
    return response;
};

export default submitFeedbackAPI;
