// api/reportService.js
import axios from 'axios';

const BASE_URL_REPORT = process.env.REACT_APP_API_REPORT

export const reportService = {
  getAllReports: async () => {
    const response = await axios.get(BASE_URL_REPORT);
    return response.data.payload;
  },

  generateReport: async ({ test_id, document_type, input_prompt }) => {
    const response = await axios.post(BASE_URL_REPORT, {
      test_id,
      document_type,
      input_prompt
    });
    return response.data.payload;
  }
};