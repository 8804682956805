import React from 'react';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/material';

const LogoSection = () => {
    return (

        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '30px',
            fontWeight: 'bold',
        }}>
            <Link underline="none" href='https://www.dartcouncil.org' sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <img src="/dart-logo.png" alt="DART Logo" style={{
                    height: '1em',
                    marginRight: '5px'
                }} />
                <Typography sx={{ fontWeight: 'bold', mt: '8px', fontFamily: "Josefin Sans" , fontSize: '32px'}} variant="h4">DART</Typography>
            </Link>
        </Box>
    );
};

export default LogoSection;

