import {React, useEffect} from 'react'


const ReportsComponent = (props) =>{
    const {payload} = props

    return(
        <div>
            Reports page
        </div>
    )
}

export default ReportsComponent;