import { Box } from '@mui/material';
import LoadingComponent from './LoadingComponent';
const TestLoadingComponent = ({loadMessage}) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
        >
            <LoadingComponent loadMessage={loadMessage} />
        </Box>
    )
}

export default TestLoadingComponent;
