import React, { useState } from 'react';
import axios from 'axios';

const Payment = () => {
  const [orderId, setOrderId] = useState(null);

  const handleRegister = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_PAYMENT;
      const response = await axios.post(baseUrl + '/order');
      setOrderId(response.data.id);
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const handlePayment = () => {
    if (!orderId) {
      console.error('Order ID is not available');
      return;
    }

    const options = {
      key: "rzp_test_xIfau3VmqsIyLp", // Replace with your Razorpay key ID
      amount: "500", // This should match the amount in your server-side code
      currency: "INR",
      name: "Your Company Name",
      description: "Test Transaction",
      order_id: orderId,
      handler: function (response) {
        alert("Payment successful. Payment ID: " + response.razorpay_payment_id);
        alert("Order ID: " + response.razorpay_order_id);
        alert("Razorpay Signature: " + response.razorpay_signature);
      },
      prefill: {
        name: "Test User",
        email: "testuser@example.com",
        contact: "9999999999"
      },
      notes: {
        address: "Test Address"
      },
      theme: {
        color: "#3399cc"
      }
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <div>
      <button onClick={handleRegister}>Register</button>
      {orderId && <button onClick={handlePayment}>Pay</button>}
    </div>
  );
};

export default Payment;