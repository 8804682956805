import React, { useState, memo } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalculateIcon from '@mui/icons-material/Calculate';
import Calculator from '../Calculator/Calculator';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
const TestTools = (props) => {
    const { text, timeLeft, handleMoveToNextSection, handleZoomIn, handleZoomOut, sectionGuidelines } = props;
    console.log("sectionGuidelines");

    console.log(sectionGuidelines);


    const theme = useTheme();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [openCalculator, setOpenCalculator] = useState(false);
    const [timeUpDialog, setTimeUpDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmExit = () => {
        navigate('/');
    };

    const handleOpenCalculator = () => {
        setOpenCalculator(true);
    };

    const handleCloseCalculator = () => {
        setOpenCalculator(false);
    };

    const countdownRenderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            setTimeUpDialog(true);
            return <span>Time's up!</span>;
        } else {
            return <span>{minutes} minutes</span>;
        }
    };

    const handleTimeUp = () => {
        handleMoveToNextSection();
        setTimeUpDialog(false);
    };

    const showTimeUpDialog = () => {
        setTimeUpDialog(true);
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <IconButton color="primary" onClick={handleOpenDialog} sx={{
                color: theme.palette.primary.main,
                backgroundColor: 'white',
                position: 'absolute',
                top: '0px',
                right: '160px',
                borderRadius: '50%',
            }}>
                <CloseIcon />
            </IconButton>

            {/* Calculate Icon */}
            <IconButton color="primary" onClick={handleOpenCalculator} sx={{
                color: theme.palette.primary.main,
                backgroundColor: 'white',
                position: 'absolute',
                top: '0px',
                right: '190px',
                borderRadius: '50%',
            }}>
                <CalculateIcon />
            </IconButton>
            <IconButton color="primary" onClick={handleZoomIn} sx={{
                color: theme.palette.primary.main,
                backgroundColor: 'white',
                position: 'absolute',
                top: '0px',
                right: '220px',
                borderRadius: '50%',
            }}>
                <ZoomIn />
            </IconButton>
            <IconButton color="primary" onClick={handleZoomOut} sx={{
                color: theme.palette.primary.main,
                backgroundColor: 'white',
                position: 'absolute',
                top: '0px',
                right: '250px',
                borderRadius: '50%',
            }}>
                <ZoomOut />
            </IconButton>
            <Tooltip title={sectionGuidelines} arrow
                sx={{
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: '#333',  // Dark background for contrast
                        color: '#fff',  // White text for readability
                        fontSize: '0.875rem',  // Comfortable font size
                        padding: '8px 12px',  // Adequate padding for content
                    }
                }}>
                <IconButton
                    sx={{
                        color: theme.palette.primary.main,
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: '0px',
                        right: '280px',
                        borderRadius: '50%',
                    }}
                >
                    <InfoOutlinedIcon />
                </IconButton>
            </Tooltip>

            <Box sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: '0px 0px',
                height: '35px',
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '0 0 0 5px',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                minWidth: '150px', // Fixing the width of the timer box
            }}>
                <Typography variant="h6" sx={{ fontWeight: 'regular', fontSize: '14px', marginLeft: 2.3, fontFamily: '"Noto Sans"' }}>
                    {text}: <MemoizedCountdown timeLeft={timeLeft} showTimeUpDialog={showTimeUpDialog} />
                </Typography>
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to exit the test?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>No</Button>
                    <Button onClick={handleConfirmExit} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Calculator Popup */}
            <Dialog
                open={openCalculator}
                onClose={handleCloseCalculator}
                aria-labelledby="calculator-dialog-title"
                BackdropComponent={() => null}
                PaperProps={{
                    style: {
                        position: 'fixed',
                        bottom: 0,
                        right: 0,
                        margin: 0,
                        maxWidth: '100%',
                        maxHeight: '50vh',
                    }
                }}
            >
                <IconButton
                    onClick={handleCloseCalculator}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Calculator />
            </Dialog>
            <Dialog
                open={timeUpDialog}
                aria-labelledby="time-up-dialog-title"
                aria-describedby="time-up-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="time-up-dialog-description">
                        Time's up! Moving to the next section.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTimeUp} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

};

export default TestTools;

const MemoizedCountdown = memo(({ timeLeft, showTimeUpDialog }) => {
    const countdownRenderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            showTimeUpDialog();
            return <span>Time's up!</span>;
        } else {
            return <span>{minutes} minutes</span>;
        }
    };

    return (
        <Countdown
            date={Date.now() + timeLeft}
            renderer={countdownRenderer}
        />
    );
}, (prevProps, nextProps) => {
    return prevProps.timeLeft === nextProps.timeLeft;
});