import { Grid, Paper, Typography, Button, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Create a custom theme with Noto Sans for the body text and Sedan for the heading
const theme = createTheme({
    typography: {
        fontFamily: 'Noto Sans, Arial, sans-serif',
        body1: {
            fontFamily: 'Noto Sans, Arial, sans-serif',
            color: '#215F9A'
        },
        body2: {
            fontFamily: 'Noto Sans, Arial, sans-serif',
            color: '#215F9A'
        },
        h5: {
            fontFamily: 'Sedan, Arial, sans-serif', // Apply Sedan font to the heading
            fontWeight: 'bold', // Make the heading bold
            color: '#215F9A', // Blue color for the title
        },
        subtitle1: {
            fontFamily: 'Josefin Sans, Arial, sans-serif', // Default sans-serif font
            fontWeight: 'bold', // Make the DART text bold
            color: '#215F9A', // Blue color for the DART text
            fontSize: '2.2rem' // Adjust font size to be comparable to the logo height
        }
    },
});

const convertMillisecondsToMinutes = (milliseconds) => {
    return milliseconds / 60000;
};

const convertQuestionTypesToString = (questionTypes) => {
    return questionTypes ? questionTypes.join(', ') : '';
};

const SectionView = (props) => {

    const { handleStartSectionButtonClicked, payload } = props;
    const content = payload.content;

    return (
        <ThemeProvider theme={theme}>
            <Box 
                sx={{ 
                    height: '100vh', 
                    overflow: 'hidden', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'space-between', 
                    backgroundImage: 'url(/background.png)', 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative'
                }}
            >
                {/* Content Container */}
                <Box 
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    {/* First Section */}
                    <Grid container spacing={2} sx={{ justifyContent: 'left' }}>
                        <Grid item xs={12} md={8}>
                        <Paper elevation={3} sx={{
                                padding: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                height: 'auto',
                                width: '90vw',
                                position: 'relative'
                            }}>
                                {/* Blue Rectangle on the Left */}
                                <Box sx={{ 
                                    position: 'absolute', 
                                    left: 0, 
                                    top: 0, 
                                    bottom: 0, 
                                    width: '5px', 
                                    backgroundColor: '#215F9A' // Blue color for the rectangle
                                }} />

                                {/* DART Logo and Text */}
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                    <img src="/dart-logo.png" alt="DART Logo" style={{ height: '30px', marginRight: '3px', marginBottom: '7px' }} />
                                    <Typography variant="subtitle1" component="span" >
                                        DART
                                    </Typography>
                                </Box>
                                
                                {/* Headline */}
                                <Typography variant="h5" component="h2" gutterBottom>
                                 {content.section_type}   Section Overview
                                </Typography>
                                
                                {/* Aligning all the text */}
                                <Box sx={{ textAlign: 'left', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'left', paddingTop: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: 2 }}>
                                        <img src="/time-icon.png" alt="Time Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Time Allotted: <br /></strong>  {convertMillisecondsToMinutes(content.section_allotted_time)} min for the entire section.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                        <img src="/back-icon.png" alt="Navigation Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Backward Navigation: <br /></strong> Not allowed during the section.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                        <img src="/type-icon.png" alt="Question Type Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Question Types: <br /></strong> {convertQuestionTypesToString(content.question_types)} type questions.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                        <img src="/tick.svg" alt="Guidelines Type Icon" style={{ height: '40px', marginRight: '4px' }} />
                                        <Typography variant="body2" component="p">
                                            <strong>Answer Guidelines:  <br /> </strong> {content.section_guidelines}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Second Section - Button at the Bottom */}
                    <Box sx={{ padding: 3, display: 'flex', justifyContent: 'center', marginTop: 0 }}>
                        <Button 
                            variant="contained" 
                            onClick={handleStartSectionButtonClicked}
                            sx={{ 
                                backgroundColor: '#fdd835', // Yellow color
                                color: '#0d47a1', // Blue text color
                                padding: '5px 20px', 
                                fontFamily: 'Sedan',
                                fontSize: '1rem', 
                                fontWeight: 'bold', // Make button text bold
                                textTransform: 'none', 
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: '#0d47a1', // Blue background on hover
                                    color: '#fdd835', // Yellow text on hover
                                }
                            }}
                        >
                            Start Section
                        </Button>
                    </Box>
                </Box>

                {/* Overlay for background */}
                <Box 
                    sx={{ 
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        width: '100%', 
                        height: '100%', 
                        backgroundColor: 'rgba(255, 255, 255, 0.5)', // Optional: add a semi-transparent overlay if needed
                        zIndex: 0,
                    }} 
                />
            </Box>
        </ThemeProvider>
    );
}

export default SectionView;
