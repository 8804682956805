import React from 'react';
import './FooterSection.css';

const FooterSection = () => {
    return (



        <div className="footer-section">

            <a href='https://www.dartcouncil.org/resources'>
                <div className="footer-item">
                    <div className="icon resources-icon"></div>
                    <h3>Resources</h3>
                    <p>Frameworks, Sample Cases and Assessment Report</p>
                </div>
            </a>

            <a href="https://www.dartcouncil.org/about">
                <div className="footer-item">
                    <div className="icon about-icon"></div>
                    <h3>About DART Test</h3>
                    <p>Learn story of DART & understand the principles behind the test.</p>
                </div>
            </a>

            <a href="https://www.dartcouncil.org/">
                <div className="footer-item">
                    <div className="icon take-icon"></div>
                    <h3>Take DART!</h3>
                    <p>Ready? Go for it!</p>
                </div>
            </a>


            <div className="footer-item">
                <div className="icon contact-icon"></div>
                <h3>Contact Us</h3>
                <p>Reach out to us in case of any concerns or queries.</p>
            </div>
        </div>
    );
};

export default FooterSection;
