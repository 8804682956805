import './App.css';
import UserLandingPage from './TestInterface/UserLandingPage';
import ProtectedRoute from './UserSingUpSignIn/ProtectedRoute'
import SignUp from './UserSingUpSignIn/SignUp';
import { Routes, Route } from "react-router-dom"
import TestRegistrationPage from './QuizComponents/TestRegistrationPage'
import TestContainer from './QuizComponents/TestContainer';
import {React} from 'react'
import EndOfTestComponent from './QuizComponents/EndOfTestComponent';
import ReportsComponent from './TestInterface/ReportsComponent';
import AdminConsole from './AdminConsole/AdminConsole'
import Payment from './payment/Payment';
function App() {
  return (


    <div className="App">
      <Routes>

        <Route path="signup" element = {<ProtectedRoute component = {SignUp}/> }/>
        <Route path="/" element = {<ProtectedRoute component = {UserLandingPage}/>}/>
        <Route path="" element = {<ProtectedRoute component = {UserLandingPage}/>} />
        <Route path='/register' element = {<ProtectedRoute component = {TestRegistrationPage}/>}  />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
        <Route path='/test/:testId' element={<ProtectedRoute component={TestContainer} />} />
        <Route path='/endoftest' element = {<ProtectedRoute component = {EndOfTestComponent}/>} />
        <Route path='/reports' element = {<ProtectedRoute component = {ReportsComponent}/>} />
        <Route path='/admin' element = {<ProtectedRoute component = {AdminConsole}/>} />
        <Route path='/test-payment' element = {<ProtectedRoute component = {Payment}/>} />


      </Routes>
    </div>
  )
}

export default App;


