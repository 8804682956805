import React, { useState, useMemo, useEffect } from 'react';
import { Button, TextField, Typography, Box, FormControl, InputLabel, Select, MenuItem, Grid, Divider, Paper, Autocomplete } from '@mui/material';
import TopBar from '../TestInterface/TopBar';
import { useNavigate } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import registerTest from "../APIMethods/registerTest";
import registerUser from "../APIMethods/registerUser";
import getProducts from "../APIMethods/getProducts";
import applyCoupon from "../APIMethods/applyCoupon"
import LoadingComponent from '../LoadingComponents/LoadingComponent';
import FooterSection from '../Footer/FooterSection';
import { FormLabel, RadioGroup, Radio } from '@mui/material';
import { InputAdornment, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Carousel from './Carousel';
import CountriesData from '../Constants/CountriesData.json'
import { LinearProgress } from '@mui/material';


import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import getUserDetails from '../APIMethods/getUserDetails';

const TestRegistrationPage = (props) => {

	const [open, setOpen] = useState(false);
	const [appliedDiscount, setAppliedDiscount] = useState(null);

	const navigate = useNavigate();
	const { email } = props;
	const [requestError, setRequestError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [couponError, setCouponError] = useState(false);
	const [formPart, setFormPart] = useState(1);
	const [isFormPart1Valid, setIsFormPart1Valid] = useState(false);

	const baseUrl = process.env.REACT_APP_API_PAYMENT;
	const razorpay_key = process.env.REACT_APP_RAZORPAY_KEY;

	const checkFormPart1Validity = (data) => {
		console.log("check form 1 entered");
		console.log(data);
		if (data) {
			// console.log("check form 2 entered");
			return data.firstName.trim() !== '' &&
				data.lastName.trim() !== '' &&
				data.city.trim() !== '' &&
				data.state.trim() !== '' &&
				data.country.trim() !== '';
		}
		return false;
	};

	const checkFormPart2Validity = (data) => {
		return data.discipline !== '' && data.productId !== '';
	};

	const handleProceed = () => {
		if (formPart === 1) {
			if (checkFormPart1Validity(formData)) {
				if (userDetails) {
					console.log("handleProceed 4");
					const userRegistrationData = {
						first_name: formData.firstName,
						last_name: formData.lastName,
						state: formData.state,
						city: formData.city,
						country: formData.country,
						college: formData.college,
						email_id: email
					};
					registerUserMutation.mutate(userRegistrationData);
				}
			}
		}
	};

	const handleBack = () => {
		setFormPart(1);
	};

	const [formData, setFormData] = useState({
		fullName: '',
		firstName: '',
		lastName: '',
		discipline: '',
		college: '',
		industry1: '',
		industry2: '',
		couponCode: '',
		agreeToTerms: false,
		city: '',
		country: '',
		state: '',
		productId: ''
	});

	const countries = useMemo(() => CountriesData, []);

	const states = useMemo(() => {
		const selectedCountry = countries.find(c => c.country === formData.country);
		return selectedCountry ? selectedCountry.states : [];
	}, [countries, formData.country]);


	const handleDialogToggle = () => {
		setOpen(!open);
	};

	const { data: products, isLoading: isLoadingProducts, isFetching: isFetchingLoading, error, refetch } = useQuery({
		queryKey: ['products', formData.country],
		queryFn: () => {
			console.log("tnjgfrngeirge");
			const response = getProducts(formData.country);
			return response;
		},
		enabled: false
	});
	const getUniqueSubjects = (products) => {
		if (!products) return [];
		return [...new Set(products.map(product => product.subject.subject_label))];
	};
	const { data: userDetails, isLoading: isUserDetailsLoading, error: errorLoadingUserDetails } = useQuery({
		queryKey: ['userDetails', email],
		queryFn: () => getUserDetails(email),
	});

	useEffect(() => {
		console.log(userDetails);
		if (userDetails) {
			const requiredFields = ['first_name', 'last_name', 'city', 'state', 'country'];
			const isAnyFieldEmpty = requiredFields.some(field => !userDetails[field]);

			if (isAnyFieldEmpty) {
				console.log("eneteereefs 1");
				setFormPart(1);
			} else {
				console.log("eneteereefs 2");

				setFormData({
					...formData,
					firstName: userDetails.first_name,
					lastName: userDetails.last_name,
					city: userDetails.city,
					state: userDetails.state,
					country: userDetails.country,
					college: userDetails.college
				});
				console.log("eneteereefs 3");
				setFormPart(2);
				// console.log("eneteereefs 3");
				// console.log(formPart);
				// refetch();
			}
		}
	}, [userDetails, refetch]);

	useEffect(() => {
		console.log("entered use effect small 1");
		console.log(formData.country);

		if (formData.country) {
			console.log("entered use effect small")
			refetch();
		}
	}, [formData.country, refetch]);

	const applyCouponMutation = useMutation({
		mutationFn: ({ couponCode, productId }) => applyCoupon(couponCode, productId),
		onSuccess: (data) => {
			if (data.code === "404") {
				setCouponError(true);
				setAppliedDiscount(null);
			} else {
				setCouponError(false);
				setAppliedDiscount(data.payload.discounted_price);
			}
		},
		onError: (error) => {
			console.error("Error applying coupon:", error);
			setCouponError(true);
			setAppliedDiscount(null);
		}
	});
	const handleApplyCoupon = () => {
		if (formData.couponCode && formData.productId) {
			applyCouponMutation.mutate({
				couponCode: formData.couponCode,
				productId: formData.productId
			});
		}
	};

	const registerUserMutation = useMutation({
		mutationFn: (userData) => registerUser(userData),
		onSuccess: () => {
			setFormPart(2);
			refetch(formData.country); // Fetch products after successful user registration
		},
		onError: (error) => {
			console.error('User registration failed:', error);
			// Handle error (e.g., show error message to user)
		}
	});

	const registerTestMutation = useMutation({
		mutationFn: (queryParams) => registerTest(queryParams),
		onSuccess: (data) => {
			handleRegisterTestResponse(data);
		},
		onError: (error) => {
			console.error('Registration failed:', error);
		}
	});

	const handleRegisterTestResponse = (data) => {
		const errorCode = data.code;
		if (errorCode === "406") {
			setRequestError(true);
			setErrorMessage('Invalid coupon code');
		}
		else if (errorCode === "409") {
			setRequestError(true)
			const testId = data.payload.test_id;
			setErrorMessage(
				<span>
					Already registered for test. {' '}
					<MuiLink component={RouterLink} to={`/test/${testId}`}>
						Open Registered test
					</MuiLink>
				</span>
			);
		}
		else if (data.payload.order_id) {
			handleRazorpayPayment(data.payload.order_id, data.payload.amount);
		}
		else {
			const testId = data.payload.test_id;
			// navigate(`/test/${testId}`);
			navigate('/');

			setRequestError(false);
		}
	}

	const handleRazorpayPayment = (orderId, amount) => {
		console.log("handleRazorpayPayment called with:", { orderId, amount });
		const options = {
			key: razorpay_key, // Replace with your Razorpay key ID
			amount: amount,
			currency: "INR",
			name: "Dart Council",
			description: "Test Registration",
			order_id: orderId,
			handler: function (response) {
				console.log("Payment successful", response);
				// Call your server to verify the payment
				const baseUrl = process.env.REACT_APP_API_PAYMENT;
				console.log(baseUrl);
				fetch(baseUrl + '/verify-payment', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(response)
				})
					.then(res => res.json())
					.then(data => {
						if (data.success) {
							navigate('/');
						} else {
							// Handle payment verification failure
						}
					});
			},
			prefill: {
				name: formData.firstName + ' ' + formData.lastName,
				email: email,
			},
			theme: {
				color: "#3399cc",
			},
		};
		console.log(options);

		const rzp1 = new window.Razorpay(options);
		rzp1.open();
	};
	const handleChange = (e) => {
		const { name, value, checked } = e.target;
		if (name === 'couponCode') {
			setCouponError(false);
		}
		if (name === 'productId') {
			setAppliedDiscount(null);
		}
		if (name === 'discipline') {
			// Reset productId when discipline changes
			setFormData(prev => ({
				...prev,
				productId: '',
				[name]: value
			}));
			return;
		}
		
		const updatedFormData = { ...formData, [name]: value };

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: name === 'agreeToTerms' ? checked : value,
		}));
		setIsFormPart1Valid(checkFormPart1Validity(updatedFormData));

	};




	const handleSubmit = () => {
		const testRegistrationData = {
			full_name: `${formData.firstName} ${formData.lastName}`,
			first_name: formData.firstName,
			last_name: formData.lastName,
			test_type: "TEST", // Assuming this is always "TEST"
			email_id: email,
			subject: formData.discipline,
			industry1: 'Consumer Goods & Retail',
			industry2: 'Technology',
			couponCode: formData.couponCode,
			product_id: parseInt(formData.productId)
		};
		registerTestMutation.mutate(testRegistrationData);
	};
	const renderFormPart2 = () => {
		return (
			<>
				<Stack>
					<FormControl fullWidth margin="normal" required size='small'>
						<InputLabel id="select-discipline-label">Select Discipline</InputLabel>
						<Select
							labelId="select-discipline-label"
							id="discipline"
							name="discipline"
							value={formData.discipline}
							label="Select Discipline"
							onChange={handleChange}
						>
							{/* <MenuItem value={"Operations"}>General Management/Consulting</MenuItem>
							<MenuItem value={"Management"} disabled={true}>Product Management - Coming Soon</MenuItem>
							<MenuItem value={"Analytics"} disabled={true}>Strategy & Analytics - Coming Soon</MenuItem>
							<MenuItem value={"Data Science"} disabled={true}>Venture Capital - Coming Soon</MenuItem>
							<MenuItem value={"Marketing"} disabled={true}>Marketing & Strategy - Coming Soon</MenuItem> */}
							{products ? getUniqueSubjects(products).map((subject) => (
								<MenuItem key={subject} value={subject}>
									{subject}
								</MenuItem>
							)) : null}
						</Select>
					</FormControl>
				</Stack>

				<br />
				{(isLoadingProducts || isFetchingLoading) ? (
					<LoadingComponent loadMessage="Loading test types..." />
				) : error ? (
					<Typography color="error">Error loading test types: {error.message}</Typography>
				) : products && products.length > 0 ? ( // Add check for products existence and length
					<Stack direction="row" spacing={2} alignItems="flex-start">
						<FormControl component="fieldset" margin="normal" sx={{ flex: 1 }}>
							{(formData.discipline) ?<FormLabel component="legend">Select test type</FormLabel> : <FormLabel></FormLabel>}
							<RadioGroup
								aria-label="test-type"
								name="productId"
								// value={formData.productId}
								onChange={handleChange}
							>
								{products
									.filter(product => product.subject.subject_label === formData.discipline)
									.map((product) => (
										<FormControlLabel
											key={product.product_id}
											value={product.product_id}
											control={<Radio />}
											label={`${product.test_format_name}`}
										/>
									))}
							</RadioGroup>
						</FormControl>
						<Stack spacing={2} sx={{ flex: 1, mt: 2 }}>
							<Stack spacing={2} direction="row">
								<TextField
									fullWidth
									id="couponCode"
									label="Coupon Code"
									name="couponCode"
									value={formData.couponCode}
									onChange={handleChange}
									size='small'
									error={couponError}
									InputProps={{
										endAdornment: couponError && (
											<InputAdornment position="end">
												<ErrorIcon color="error" />
											</InputAdornment>
										),
									}}
								/>
								<Button
									sx={{
										width: '50%',
										padding: '6px 16px',
										fontSize: '0.875rem'
									}}
									variant="contained"
									onClick={handleApplyCoupon}
									disabled={!formData.couponCode || !formData.productId || !formData.discipline}
								>
									Check
								</Button>
							</Stack>
							<Box sx={{ mt: 2 }}>
								{(() => {
									const selectedProduct = products.find(p => p.product_id === formData.productId);
									const originalPrice = selectedProduct?.price || 0;
									const promotionalPrice = selectedProduct?.promotional_price;
									const discountedPrice = appliedDiscount;

									return (
										<>
											<Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
												Price :
												{discountedPrice !== null ? (
													<>
														<Typography component="span" sx={{ textDecoration: 'line-through', color: 'text.secondary', fontSize: '1.5rem', mr: 1 }}>
															₹{originalPrice}
														</Typography>
														₹{discountedPrice}
													</>
												) : promotionalPrice ? (
													<>
														<Typography component="span" sx={{ textDecoration: 'line-through', color: 'text.secondary', fontSize: '1.5rem', mr: 1 }}>
															₹{originalPrice}
														</Typography>
														₹{promotionalPrice}
													</>
												) : (
													`₹${originalPrice}`
												)}
											</Typography>
										</>
									);
								})()}
							</Box>
						</Stack>
					</Stack>) : (
					<Typography>No products available in Region</Typography>
				)}


				{/* Dialog Component */}
				<Dialog open={open} onClose={handleDialogToggle}>
					<DialogTitle>{"Terms and Conditions"}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Here you can detail your terms and conditions for the user to read.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleDialogToggle}>Close</Button>
					</DialogActions>
				</Dialog>
				<Stack direction="row" spacing={2} sx={{ mt: 1 }} justifyContent="space-between" >
					<Button
						variant="outlined"
						sx={{ mb: 2, width: 150 }}
						onClick={handleBack}
					>
						<Typography variant="h6" sx={{ fontWeight: 600 }}>
							Edit Profile Info
						</Typography>
					</Button>
					<Button
						variant="contained"
						sx={{ mb: 2, width: 300 }}
						onClick={handleSubmit}
						disabled={!isFormPart1Valid || !formData.productId || !formData.discipline}
					>
						<Typography variant="h6" sx={{ fontWeight: 600 }}>
							Complete Payment
						</Typography>
						{registerTestMutation.isPending && (
							<LinearProgress
								sx={{
									position: 'absolute',
									bottom: 0,
									left: 0,
									right: 0,
								}}
							/>
						)}
					</Button>
				</Stack>

				{requestError && (
					<Typography color="error" sx={{ mt: 1 }}>
						{errorMessage}
					</Typography>
				)}
			</>
		)
	}
	const renderFormPart1 = () => {
		return (

			<>
				<Stack
					direction="row"
					spacing={2}
					sx={{ mb: 1 }}
				>
					<TextField
						margin="normal"
						required
						fullWidth
						id="firstName"
						label="First Name"
						name="firstName"
						autoFocus
						value={formData.firstName}
						onChange={handleChange}
						size='small'
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="lastName"
						label="Last Name"
						name="lastName"
						autoFocus
						value={formData.lastName}
						onChange={handleChange}
						size='small'
					/>
				</Stack>

				<br />

				<Stack
					direction="row"
					spacing={2}
					sx={{ mb: 1 }}
				>
					<TextField
						margin="normal"
						fullWidth
						id="college"
						label="College Name (Optional)"
						name="college"
						autoComplete="college"
						autoFocus
						value={formData.college}
						onChange={handleChange}
						size='small'
					/>
				</Stack>
				<br />
				<Stack
					direction="row"
					spacing={2}
					sx={{ mb: 1 }}  // Add larger margin bottom

				>
					<Autocomplete
						value={formData.country}
						fullWidth
						onChange={(event, newValue) => {
							setFormData((prevFormData) => ({
								...prevFormData,
								country: newValue,
								state: ''
							}))
						}}
						options={countries.map((countryData) => countryData.country)}
						size="small"
						renderInput={(params) =>
							<TextField {...params}
								required
								label="Country"
							/>
						}
					/>
					<Autocomplete
						value={formData.state}
						fullWidth
						onChange={(event, newValue) => {
							setFormData((prevFormData) => ({
								...prevFormData,
								state: newValue
							}))
						}}
						options={states}
						size="small"
						renderInput={(params) =>
							<TextField {...params}
								required
								label="State"
							/>
						}
					/>
				</Stack>
				<TextField
					margin="normal"
					fullWidth
					required
					id="city"
					label="City"
					name="city"
					autoComplete="city"
					autoFocus
					value={formData.city}
					onChange={handleChange}
					size='small'
				/>
				<Button
					variant="contained"
					sx={{ mt: 5, width: 300 }}
					onClick={handleProceed}
					disabled={!checkFormPart1Validity(formData)}

				>
					<Typography variant="h6" sx={{ fontWeight: 600 }}>
						Proceed
					</Typography>
					{registerUserMutation.isPending && (
						<LinearProgress
							sx={{
								position: 'absolute',
								bottom: 0,
								left: 0,
								right: 0,
							}}
						/>
					)}
				</Button>
				{registerTestMutation.isPending && <LoadingComponent loadMessage={"Registering.."} />}

			</>
		)
	}
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
			<TopBar />
			<Box sx={{ flexGrow: 1, mt: '20px' }}>

				<Grid container spacing={0}>

					<Grid item xs={6.5} >
						<Paper elevation={0} sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '60vh',
							width: '100%'
						}}>
							<Box sx={{
								width: '80%',
								maxWidth: '80%',
								margin: 'auto'
							}}>
								<Carousel />
							</Box>
						</Paper>
					</Grid>

					<Divider orientation="vertical" flexItem />

					<Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '70%',
							maxWidth: '70%'
						}}>
							<Paper elevation={10} sx={{
								p: 4,
								height: '40vh',
								maxHeight: '40vh',
								overflow: 'auto',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'flex-start',
								alignItems: 'stretch'
							}}>
								<Typography variant="h4" align='left' gutterBottom sx={{ fontWeight: 600, fontSize: 31 }}>
									Register
								</Typography>
								<Box component="form" noValidate sx={{ width: '100%' }}>
									{isUserDetailsLoading ? (
										<LoadingComponent loadMessage="Loading user details..." />
									) : (
										formPart === 1 ? renderFormPart1() : renderFormPart2()
									)}
								</Box>
							</Paper>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<FooterSection />
		</Box>
	);
};

export default TestRegistrationPage;

//TODO : Partial information registration before proceed button clicked
{/* {isLoading ? (
									<LoadingComponent loadMessage="Loading test types..." />
								) : error ? (
									<Typography color="error">Error loading test types: {error.message}</Typography>
								) : products ? (
									products.map((product) => (
										<FormControlLabel
											key={product.product_id}
											value={product.product_id}
											control={<Radio />}
											label={`${product.test_name}`}
										/>
									))
								) : null} */}