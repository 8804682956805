import axios from "axios";

const submitAnswerAPI = async(emailId, response, testId, questionType, recordedVideoFile) => {
    const url = process.env.REACT_APP_API_TEST + '/submitAnswer';

    let formData = new FormData();

    const answerResponse = {
        single_choice_response: questionType === 'SC_MCQ' ? response : null,
        multiple_choice_response: questionType === 'MC_MCQ' ? response : null,
        numeric_response: questionType === 'NUMERIC' ? response : null,
        subjective_response: questionType === 'SUBJECTIVE' ? response : null,
    };

    formData.append('response', new Blob([JSON.stringify({
        email_id: emailId,
        response: answerResponse,
        question_type: questionType,
        test_id: testId
    })], { type: 'application/json' }));

    if(recordedVideoFile!==null){
        formData.append('video', recordedVideoFile);
    }

    const headers = {
        accept: '*/*',
        'ngrok-skip-browser-warning': 'true'
    };

    const config = {
        headers: {
            ...headers,
            'Content-Type': 'multipart/form-data'
        }
    };

    const responseData = await axios.post(url, formData, config);
    return responseData;
}

export default submitAnswerAPI;
